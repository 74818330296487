import {Injectable} from '@angular/core';
import {BaseService} from './base.service';


@Injectable()
export class GlobalService extends BaseService {

    public themeLoaded = false;
    public loading = false;
    public orientation;

    public get selectedNotice() {
        const result = localStorage.getItem('selectedNotice');
        if (result) {
            return JSON.parse(result);
        }
        return null;
    }

    public set selectedNotice(value) {
        if (value) {
            localStorage.setItem('selectedNotice', JSON.stringify(value));
        } else {
            localStorage.removeItem('selectedNotice');
        }
    }

    convertToDataURLviaCanvas(url, outputFormat) {
        return new Promise( (resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                console.log('img', img);
                let canvas = <HTMLCanvasElement> document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                // callback(dataURL);
                canvas = null;
                resolve(dataURL);
            };
            img.src = 'https://cors-anywhere.herokuapp.com/' + url;
        });
    }

}
